<template>
  <div>
    <div class="header">
      <h2>Select Region Of Interest</h2>
    </div>
    <hr />

    <input
      ref="input"
      type="file"
      name="image"
      accept="image/*"
      @change="setImage"
    />

    <div class="content">
      <section class="cropper-area">
        <div class="img-cropper">
          <vue-cropper
            ref="cropper"
            :src="imgSrc"
            preview=".preview"
            :viewMode="3"
            :zoomable="false"
            :autoCrop="false"
          />
        </div>
        <div class="actions">
          <!-- <a href="#" role="button" @click.prevent="zoom(0.2)"> Zoom In </a>
          <a href="#" role="button" @click.prevent="zoom(-0.2)"> Zoom Out </a>
          <a href="#" role="button" @click.prevent="move(-10, 0)">
            Move Left
          </a>
          <a href="#" role="button" @click.prevent="move(10, 0)">
            Move Right
          </a>
          <a href="#" role="button" @click.prevent="move(0, -10)"> Move Up </a>
          <a href="#" role="button" @click.prevent="move(0, 10)"> Move Down </a>
          <a href="#" role="button" @click.prevent="rotate(90)">
            Rotate +90deg
          </a>
          <a href="#" role="button" @click.prevent="rotate(-90)">
            Rotate -90deg
          </a>
          <a ref="flipX" href="#" role="button" @click.prevent="flipX">
            Flip X
          </a>
          <a ref="flipY" href="#" role="button" @click.prevent="flipY">
            Flip Y
          </a> -->
          <!-- <a href="#" role="button" @click.prevent="cropImage"> Crop </a> -->
          <a href="#" role="button" @click.prevent="reset"> Reset </a>
          <!-- <a href="#" role="button" @click.prevent="getData"> Get Data </a>
          <a href="#" role="button" @click.prevent="setData"> Set Data </a>
          <a href="#" role="button" @click.prevent="getCropBoxData">
            Get CropBox Data
          </a>
          <a href="#" role="button" @click.prevent="setCropBoxData">
            Set CropBox Data
          </a> -->
          <!-- <a href="#" role="button" @click.prevent="showFileChooser">
            Upload Image
          </a> -->
          <a href="#" class="warning" role="button" @click.prevent="doOCR">
            Do OCR
          </a>

          <v-btn
            @click="dialogUseCustomAiModel = true"
            color="primary"
            class="ml-6"
          >
            Use Custom Ai Model
          </v-btn>
        </div>

        <!-- <textarea v-model="data" /> -->

        <div class="actions">
          <a href="#" role="button" @click.prevent="setROIDialogRoi(false)">
            Cancel
          </a>
        </div>
      </section>

      <section class="preview-area ml-4">
        <p>Preview</p>
        <div class="preview" />

        <!-- <p>Cropped Image</p>
        <div class="cropped-image">
          <img v-if="cropImg" :src="cropImg" alt="Cropped Image" />
          <div v-else class="crop-placeholder" />
        </div> -->
      </section>
    </div>

    <!-- Overlay -->
    <v-overlay :value="overlay" z-index="10000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <!-- dialogUseCustomAiModel -->
    <v-dialog
      v-model="dialogUseCustomAiModel"
      class="d-flex align-center justify-center"
      persistent
      max-width="500px"
      style="z-index: 1"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Use Custom Ai Model</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="formAiModel" lazy-validation>
              <v-row>
                <!-- Ai Model -->
                <v-col class="mt-n5" cols="12">
                  <v-select
                    v-model="aiModelName"
                    :items="aiModelNamesList"
                    :rules="aiModelNameRules"
                    required
                    attach
                  >
                    <template v-slot:label>
                      <p class="body-1">
                        Ai Model Name <span class="red--text">*</span>
                      </p>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="btn btn-primary"
            @click="dialogUseCustomAiModel = false"
          >
            Cancel
          </v-btn>
          <v-btn
            @click="validateFormAiModel()"
            color="btn btn-primary"
            class="ml-6"
          >
            <span v-if="image.did_ocr">Do OCR Again</span>
            <span v-else>Do OCR</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  name: "DocumentManagementROI",

  components: {
    VueCropper,
  },

  props: ["imgSrc", "aiModels", "aiModelNamesList", "image"],

  data() {
    return {
      overlay: false,
      cropImg: "",
      data: null,

      dialogUseCustomAiModel: false,

      aiModel: {},
      aiModelName: "",
      aiModelId: 0,
      // aiModelName
      aiModelNameRules: [(v) => !!v || "Required"],
    };
  },

  computed: {
    ...mapState({ auth: "auth", roi: "roi" }),
  },

  created() {
    //
  },

  watch: {
    "roi.imgSrc"(val) {
      this.$refs.cropper.replace(val);
      this.cropImg = "";
    },

    // imgSrc
    imgSrc(val) {
      this.$refs.cropper.replace(val);
      this.cropImg = "";
    },

    // aiModelName
    aiModelName() {
      if (this.aiModelName != "") {
        var id = this.$helpers.getIdByNameFromList(
          this.aiModels,
          this.aiModelName
        );

        if (id > 0) {
          this.aiModelId = id;

          this.aiModel = this.aiModels.find((aiModel) => aiModel.id == id);

          if (this.aiModel.status != "Ready To Use") {
            this.$swal({
              toast: true,
              position: "top-end",
              icon: "warning",
              title: "Ai Model " + this.aiModel.name + " Is Not Ready To Use",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        }
      }

      // console.log("this.aiModelId", this.aiModelId);
    },
  },

  methods: {
    ...mapMutations({
      resetAuthState: "auth/resetState",
      setUser: "auth/setUser",

      setROIImgSrc: "roi/setImgSrc",
      setROIImgId: "roi/setImgId",
      setROIImage: "roi/setImage",
      setROIDialogRoi: "roi/setDialogRoi",
    }),

    // cropImage
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        let i = new FormData();
        i.append("picture", blob);
        console.log(i);
      });
    },

    // flipX
    flipX() {
      const dom = this.$refs.flipX;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleX(scale);
      dom.setAttribute("data-scale", scale);
    },

    // flipY
    flipY() {
      const dom = this.$refs.flipY;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute("data-scale", scale);
    },

    // getCropBoxData
    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
    },

    // getData
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
    },

    // move
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },

    // reset
    reset() {
      this.$refs.cropper.reset();
    },

    // rotate
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },

    // setCropBoxData
    setCropBoxData() {
      if (!this.data) return;

      this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
    },

    // setData
    setData() {
      if (!this.data) return;

      this.$refs.cropper.setData(JSON.parse(this.data));
    },

    // setImage
    setImage(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },

    // showFileChooser
    showFileChooser() {
      this.$refs.input.click();
    },

    // zoom
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },

    // toDataURL
    toDataURL(url) {
      fetch(url)
        .then((response) => response.blob())
        .then(
          (blob) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result);
              reader.onerror = reject;
              reader.readAsDataURL(blob);
            })
        );
    },

    // validateFormAiModel
    validateFormAiModel() {
      this.$refs.formAiModel.validate();

      if (this.$refs.formAiModel.validate()) {
        if (this.aiModel.status != "Ready To Use") {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Ai Model " + this.aiModel.name + " Is Not Ready To Use",
          });
        } else {
          this.doOCRWithCustomAiModel();
        }
      }
    },

    // doOCR
    async doOCR() {
      var roi = this.$refs.cropper.getCropBoxData();
      // console.log("roi", roi);

      if (
        "height" in roi &&
        "left" in roi &&
        "top" in roi &&
        "width" in roi &&
        this.roi.imgId > 0
      ) {
        await this.doOCRWithROI(this.roi.imgId, roi);
      } else {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Please Select Region Of Interest",
        });
      }
    },

    // doOCRWithROI
    async doOCRWithROI(imgId, roi) {
      this.overlay = true;
      
      const cropperData = this.$refs.cropper.cropper.getData();
      
      const imageData = this.$refs.cropper.cropper.getImageData();

      const res = await this.$helpers.createApiData(
        `images/do-ocr-to-image-dynamic-with-roi/${imgId}`,
        {
          roi: roi,
          width: imageData.width,
          height: imageData.height,
          scale_x: cropperData.scaleX,
          scale_y: cropperData.scaleY,
        },
        "OCR Done Successfully!"
      );

      if (res.status == 200) {
        var data = res.data;

        this.setROIImage(data);
        this.setROIDialogRoi(false);

        // getUserDetails
        this.getUserDetails();
      }

      this.overlay = false;
    },

    // doOCRWithCustomAiModel
    async doOCRWithCustomAiModel() {
      var roi = this.$refs.cropper.getCropBoxData();
      // console.log("roi", roi);

      if (
        "height" in roi &&
        "left" in roi &&
        "top" in roi &&
        "width" in roi &&
        this.roi.imgId > 0
      ) {
        await this.doOCRWithCustomAiModelWithROI(this.roi.imgId, roi);
      } else {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Please Select Region Of Interest",
        });
      }
    },

    // doOCRWithCustomAiModelWithROI
    async doOCRWithCustomAiModelWithROI(imgId, roi) {
      this.overlay = true;
      
      const cropperData = this.$refs.cropper.cropper.getData();
      
      const imageData = this.$refs.cropper.cropper.getImageData();

      const res = await this.$helpers.createApiData(
        `images/do-ocr-to-image-dynamic-with-roi-and-use-custom-ai-model/${imgId}/${this.aiModelId}`,
        {
          roi: roi,
          width: imageData.width,
          height: imageData.height,
          scale_x: cropperData.scaleX,
          scale_y: cropperData.scaleY,
        },
        "OCR Done Successfully!"
      );

      if (res.status == 200) {
        var data = res.data;

        this.setROIImage(data);
        this.setROIDialogRoi(false);

        // getUserDetails
        this.getUserDetails();
      }

      this.overlay = false;
    },

    // getUserDetails
    async getUserDetails() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("user-details");

      if (res.status == 200) {
        const data = res.data;

        this.setUser(data);
      }

      this.overlay = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%;
}

.cropper-view-box {
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
}

.cropper-face {
  background-color: inherit !important;
}

.cropper-dashed,
.cropper-point.point-se,
.cropper-point.point-sw,
.cropper-point.point-nw,
.cropper-point.point-ne,
.cropper-line {
  display: none !important;
}

.cropper-view-box {
  outline: inherit !important;
}
body {
  font-family: Arial, Helvetica, sans-serif;
  width: 1024px;
  margin: 0 auto;
}

input[type="file"] {
  display: none;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 5px 0;
}

.header h2 {
  margin: 0;
}

.header a {
  text-decoration: none;
  color: black;
}

.content {
  display: flex;
  justify-content: space-between;
}

.cropper-area {
  width: 614px;
}

.actions {
  margin-top: 1rem;
}

.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062cc;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

textarea {
  width: 100%;
  height: 100px;
}

.preview-area {
  width: 307px;
}

.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}

.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}

.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}

.cropped-image img {
  max-width: 100%;
}
</style>
