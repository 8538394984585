<template>
  <div class="dashboard-inbox-details">
    <v-card class="pa-4 mt-5 pa-lg-7" elevation="1" rounded="lg">
      <!-- hr -->
      <hr class="mb-4" />

      <!-- Name and Surname -->
      <v-card
        class="pa-5 d-flex align-center justify-center secondary"
        width="100%"
        height="20px"
      >
        <p class="body-1 text-center white--text mb-n1">
          {{ auth.user.first_name }} {{ auth.user.last_name }}
        </p>
      </v-card>

      <!-- hr -->
      <hr class="my-4" />

      <!-- Main Section -->
      <v-row>
        <!-- Left -->
        <v-col cols="12" md="4">
          <div class="d-flex flex-column">
            <div>
              <div v-if="images.length > 0">
                <div v-if="image.name.split('.').pop() == 'pdf'">
                  <pdf
                    ref="pdf"
                    :src="'data:application/pdf;base64,' + image.base64"
                    :page="pdfCurrentPage"
                    @num-pages="pdfPageCount = $event"
                    @page-loaded="pdfCurrentPage = $event"
                  >
                  </pdf>

                  <div class="d-flex mt-2">
                    <v-btn
                      color="primary"
                      @click="decrementPdfCurrentPage()"
                      :disabled="decrementPdfCurrentPageDisabled"
                    >
                      <v-icon>mdi-page-previous</v-icon>
                    </v-btn>

                    <v-btn
                      class="ml-2"
                      color="primary"
                      @click="incrementPdfCurrentPage()"
                      :disabled="incrementPdfCurrentPageDisabled"
                    >
                      <v-icon> mdi-page-next </v-icon>
                    </v-btn>
                  </div>
                </div>
                <div v-else>
                  <InnerImageZoom :src="image.url" :zoomSrc="image.url" />
                </div>
              </div>
              <div v-else>
                <InnerImageZoom
                  src="https://via.placeholder.com/800"
                  zoomSrc="https://via.placeholder.com/800"
                />
              </div>
            </div>

            <div class="d-flex flex-column flex-md-row">
              <v-card class="mt-4" max-width="150" max-height="150" flat>
                <v-carousel
                  :continuous="false"
                  :show-arrows="true"
                  height="250"
                  delimiter-icon=""
                  hide-delimiters
                  hide-delimiter-background
                >
                  <v-carousel-item
                    v-for="(image, index) in images"
                    :key="index"
                  >
                    <div v-if="image.name.split('.').pop() == 'pdf'">
                      <v-img
                        @click="changeImage(image)"
                        alt="img"
                        class="img pointer"
                        lazy-src="https://via.placeholder.com/150"
                        src="@/assets/img/pdf/pdf-thumbnail.png"
                        contain
                      />
                    </div>

                    <div v-else>
                      <div v-if="image.thumbnail_url != null">
                        <v-img
                          @click="changeImage(image)"
                          alt="img"
                          class="img pointer"
                          lazy-src="https://via.placeholder.com/150"
                          :src="image.thumbnail_url"
                          contain
                        />
                      </div>

                      <div v-else>
                        <v-img
                          @click="changeImage(image)"
                          alt="img"
                          class="img pointer"
                          lazy-src="https://via.placeholder.com/150"
                          :src="image.url"
                          contain
                        />
                      </div>
                    </div>
                  </v-carousel-item>
                </v-carousel>
              </v-card>

              <v-spacer />

              <div
                v-if="images.length > 0"
                class="d-flex align-end flex-column mt-4"
              >
                <!-- Edit Image -->
                <div class="">
                  <v-btn @click="editImage(image)" color="primary" width="160">
                    Edit Image
                  </v-btn>
                </div>

                <!-- Delete Image -->
                <div class="mt-2">
                  <v-btn
                    @click="deleteImage(image)"
                    color="red"
                    class="white--text"
                    width="160"
                  >
                    Delete Image
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <!-- Right -->
        <v-col cols="12" md="8">
          <v-card class="" width="100%" flat>
            <!--  -->
            <v-card class="d-flex flex-column flex-md-row mb-4" flat>
              <!-- New Image -->
              <div v-if="$can('image_create')">
                <v-btn @click="addImage()" color="primary" dark>
                  New Image
                </v-btn>
              </div>

              <!-- Download File -->
              <v-btn @click="getFileAndDownload()" class="mt-2 mt-md-0 ml-0 ml-md-3" color="primary" dark>
                Download File
              </v-btn>
              <v-spacer />

              <!-- Do OCR -->
              <div
                class="d-flex flex-column flex-md-row mt-2 mt-md-0"
                v-if="images.length > 0"
              >
                <v-btn
                  @click="dialogUseCustomAiModel = true"
                  color="primary"
                  class="ml-md-6"
                >
                  Use Custom Ai Model
                </v-btn>

                <v-btn
                  @click="doOCR()"
                  color="warning"
                  class="ml-md-6 mt-2 mt-md-0"
                >
                  <span v-if="image.did_ocr">Do OCR Again</span>
                  <span v-else>Do OCR</span>
                </v-btn>
              </div>
            </v-card>

            <!-- header -->
            <v-card class="d-flex justify-space-between pl-4" width="100%" flat>
              <v-card flat>
                <v-tabs
                  v-model="tab"
                  active-class="secondary--text"
                  background-color="transparent"
                  color="basil"
                  grow
                >
                  <v-tab v-for="(item, index) in tabItems" :key="index">
                    {{ item }}
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                  <v-tab-item :key="0">
                    <v-card
                      class="overflow-auto"
                      color="basil"
                      width="100%"
                      min-width="900"
                      max-height="700px"
                      flat
                    >
                      <div class="mt-4">
                        <div v-if="'data' in ocr_data_object">
                          <div
                            v-for="(item, index) in ocr_data_object.data"
                            :key="index"
                            class=""
                          >
                            <div
                              v-for="(value, key, index) of item"
                              :key="index"
                            >
                              <h5 v-if="key != 'index'">
                                {{ value }}
                              </h5>
                            </div>
                          </div>
                        </div>

                        <div v-else>
                          <!--  -->
                          <h1>OCR Text</h1>
                        </div>
                      </div>
                    </v-card>
                  </v-tab-item>

                  <v-tab-item :key="1">
                    <v-card
                      class="overflow-auto"
                      color="basil"
                      width="100%"
                      min-width="900"
                      max-height="700px"
                      flat
                    >
                      <json-viewer
                        :value="ocr_data_object"
                        :expand-depth="5"
                        copyable
                        boxed
                        sort
                      ></json-viewer>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </v-card>

            <!-- Document -->
            <v-card class="d-flex justify-space-between mt-7" width="100%" flat>
              <!-- Document Created At -->
              <div class="div">
                <v-text-field
                  class="mx-2"
                  v-model="documentCreatedAt"
                  label="Document Created At"
                  append-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                ></v-text-field>
                <p class="body-1 mx-2 mt-n3">
                  {{ moment(document.created_at).add(2, "hours").fromNow() }}
                </p>
              </div>

              <v-spacer />

              <!-- Document Updated At -->
              <div>
                <v-text-field
                  class="mx-2"
                  v-model="documentUpdatedAt"
                  label="Document Updated At"
                  append-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                ></v-text-field>
                <p class="body-1 text-right mx-2 mt-n3">
                  {{ moment(document.updated_at).add(2, "hours").fromNow() }}
                </p>
              </div>
            </v-card>

            <!-- hr -->
            <hr class="my-3" />

            <!-- Document Type Tab -->
            <v-card class="d-flex justify-space-between" width="100%" flat>
              <v-card flat>
                <div class="d-flex">
                  <div class="d-flex" v-if="documentLines.length > 0">
                    <h2 class="mr-5">{{ categoryNameDisplay }}</h2>

                    <!-- Change Category -->
                    <v-btn
                      @click="dialogDeleteDocumentLines = true"
                      color="primary"
                      dark
                      class="mb-2"
                    >
                      Change Category
                    </v-btn>
                  </div>

                  <div v-if="images.length > 0">
                    <div v-if="image.name.split('.').pop() != 'pdf'">
                      <!-- ROI -->
                      <v-btn
                        @click="setROI(image)"
                        color="warning"
                        dark
                        class="mb-2 ml-4"
                      >
                        ROI
                      </v-btn>
                    </div>
                  </div>
                </div>
              </v-card>

              <v-spacer />

              <v-card flat>
                <!--  -->
              </v-card>
            </v-card>

            <!-- hr -->
            <hr class="mb-3 mt-n4" />

            <!-- Category -->
            <v-card width="100%" flat>
              <!--  -->
            </v-card>

            <template>
              <v-data-table
                :headers="headers"
                :items="imageDocumentLines"
                sort-by="id"
                :sort-desc="true"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Document Lines</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" persistent max-width="500px">
                      <template
                        v-if="$can('document_line_create')"
                        v-slot:activator="{ on, attrs }"
                      >
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          New Document Line
                        </v-btn>
                      </template>
                      <v-card :class="customClass">
                        <v-card-title>
                          <span class="text-h5">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-form ref="form" lazy-validation>
                              <v-row>
                                <!-- Categories -->
                                <v-col
                                  v-if="documentLines.length <= 0"
                                  class="mt-n5"
                                  cols="12"
                                >
                                  <v-select
                                    v-model="categoryName"
                                    :items="categoryNamesList"
                                    :rules="categoryNameRules"
                                    attach
                                    label="Categories"
                                    required
                                  >
                                    <template v-slot:label>
                                      <p class="body-1">
                                        Category Name
                                        <span class="red--text">*</span>
                                      </p>
                                    </template>
                                  </v-select>
                                </v-col>

                                <!-- Data -->
                                <v-col
                                  v-for="(
                                    documentLineOption, index
                                  ) in documentLineOptionsTextValue"
                                  class="mt-n5"
                                  cols="12"
                                  :key="index"
                                >
                                  <v-text-field
                                    v-model="documentLineOption.model"
                                    required
                                  >
                                    <template v-slot:label>
                                      <p class="body-1">
                                        {{ documentLineOption.text }}
                                        <!-- <span class="red--text">*</span> -->
                                      </p>
                                    </template>
                                  </v-text-field>
                                </v-col>

                                <!-- For current image only -->
                                <v-col
                                  v-if="documentLines.length > 0"
                                  class="mt-n10"
                                  cols="12"
                                >
                                  <v-checkbox
                                    v-model="forCurrentImageOnly"
                                    label="For Current Image Only"
                                  ></v-checkbox>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="btn btn-primary" @click="close">
                            Cancel
                          </v-btn>
                          <v-btn color="btn btn-primary" @click="save()">
                            Save
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card>
                        <v-card-title class="text-h5"
                          >Are you sure you want to delete this document
                          line?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="btn btn-primary" @click="closeDelete"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="btn btn-primary"
                            @click="deleteItemConfirm"
                            >OK</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>

                <!-- created_at -->
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.created_at="{ item }">
                  {{ $helpers.datetime(item.created_at) }}
                </template>

                <!-- updated_at -->
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.updated_at="{ item }">
                  {{ $helpers.datetime(item.updated_at) }}
                </template>

                <!-- actions -->
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    v-if="$can('document_line_update')"
                    small
                    class="mr-2 primary--text"
                    @click="editItem(item)"
                    color="primary"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    v-if="$can('document_line_delete')"
                    class="red--text"
                    small
                    @click="deleteItem(item)"
                    color="red"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <template v-slot:no-data>
                  <v-btn color="primary"> No Data Available </v-btn>
                </template>
              </v-data-table>
            </template>

            <!-- Table -->
            <div v-if="categoryNameDisplay.trim().toLowerCase() === 'table'" class="mt-4">
              <h2 class="mb-3">Display Data</h2>

              <v-data-table
                :headers="tableHeaders"
                :items="tableItems"
                class="elevation-1"
              ></v-data-table>
            </div>
          </v-card>
        </v-col>

        <v-btn
          v-scroll="onScroll"
          v-show="fab"
          fab
          dark
          fixed
          bottom
          right
          color="primary"
          @click="toTop"
        >
          <v-icon>keyboard_arrow_up</v-icon>
        </v-btn>
      </v-row>

      <v-dialog v-model="dialogImage" persistent max-width="500px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formImageTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="formImage" lazy-validation>
                <v-row>
                  <!-- img -->
                  <div v-if="imageIndex > 0">
                    <v-img
                      v-if="image.thumbnail_url != null"
                      alt="img"
                      class="img"
                      lazy-src="https://via.placeholder.com/150"
                      :src="image.thumbnail_url"
                      max-width="150"
                      contain
                    />
                    <v-img
                      v-else
                      alt="img"
                      class="img"
                      lazy-src="https://via.placeholder.com/150"
                      :src="image.url"
                      max-width="150"
                      contain
                    />
                  </div>

                  <!-- Files -->
                  <v-col class="mt-n5" cols="12">
                    <v-file-input
                      v-model="files"
                      :rules="filesRules"
                      :multiple="multiple"
                      counter
                      show-size
                      placeholder="Click here to select files"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="btn btn-primary" @click="closeImage"> Cancel </v-btn>
            <v-btn color="btn btn-primary" @click="saveImage"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogDeleteImage" max-width="500px">
        <v-card>
          <v-card-title class="text-h5"
            >Are you sure you want to delete this image?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="btn btn-primary" @click="closeDeleteImage"
              >Cancel</v-btn
            >
            <v-btn color="btn btn-primary" @click="deleteImageConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogDeleteDocumentLines" max-width="500px">
        <v-card>
          <v-card-title class="text-h5"
            >Note changing the category will result in some document lines been
            deleted</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="btn btn-primary" @click="closeDeleteDocumentLines()"
              >Cancel</v-btn
            >
            <v-btn color="btn btn-primary" @click="deleteDocumentLinesConfirm()"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Overlay -->
      <v-overlay :value="overlay" z-index="1000">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

      <!-- dialogRoi -->
      <v-dialog
        v-model="roi.dialogRoi"
        class="d-flex align-center justify-center"
        max-width="80%"
      >
        <v-card
          class="d-flex align-center justify-center"
          max-width="100%"
          height="70%"
        >
          <ROI
            :imgSrc="imgSrc"
            :aiModels="aiModels"
            :aiModelNamesList="aiModelNamesList"
            :image="image"
          />
        </v-card>
      </v-dialog>

      <!-- dialogUseCustomAiModel -->
      <v-dialog
        v-model="dialogUseCustomAiModel"
        class="d-flex align-center justify-center"
        persistent
        max-width="500px"
      >
        <v-card :class="customClass">
          <v-card-title>
            <span class="text-h5">Use Custom Ai Model</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="formAiModel" lazy-validation>
                <v-row>
                  <!-- Ai Model -->
                  <v-col class="mt-n5" cols="12">
                    <v-select
                      v-model="aiModelName"
                      :items="aiModelNamesList"
                      :rules="aiModelNameRules"
                      required
                      attach
                    >
                      <template v-slot:label>
                        <p class="body-1">
                          Ai Model Name <span class="red--text">*</span>
                        </p>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="btn btn-primary"
              @click="dialogUseCustomAiModel = false"
            >
              Cancel
            </v-btn>
            <v-btn
              @click="validateFormAiModel()"
              color="btn btn-primary"
              class="ml-6"
            >
              <span v-if="image.did_ocr">Do OCR Again</span>
              <span v-else>Do OCR</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import InnerImageZoom from "vue-inner-image-zoom";

import ROI from "@/components/elements/dashboard/inbox-details/roi/ROI";

import pdf from "vue-pdf";

import fileDownload from 'js-file-download';

export default {
  name: "DashboardInboxDetails",

  components: {
    InnerImageZoom,
    ROI,
    pdf,
  },

  data: () => ({
    overlay: false,

    dialogImage: false,
    dialogDeleteImage: false,
    dialogDeleteDocumentLines: false,

    dialogRoi: false,
    dialogUseCustomAiModel: false,
    imgSrc: "",

    key: 0,

    tab: null,
    // "Vendor",
    tabItems: ["OCR Text", "JSON"],
    documentTypeTab: null,
    documentTypeTabItems: ["Document", "Bill", "Purchase Order", "Check"],
    text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
     enim ad minim veniam, quis nostrud exercitation ullamco laboris
      nisi ut aliquip ex ea commodo consequat.`,

    documentDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    documentDateMenu: false,

    search: "",

    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),

    documentCreatedAt: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),

    documentUpdatedAt: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),

    dateMenu: false,

    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "Id", align: "start", value: "id" },
      { text: "Document Id", value: "document_id" },
      { text: "Category Id", value: "category_id" },
      { text: "Created At", value: "created_at" },
      { text: "Updated At", value: "updated_at" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    document: {},
    image: {},
    documentLines: [],
    imageDocumentLines: [],

    documentLineOptions: [],
    documentLineOptionsTextValue: [],

    categories: [],

    categoryId: 0,
    categoryName: "",
    categoryNameDisplay: "",
    categoryNamesList: [],

    documentId: null,
    documentIdsList: [],

    editedIndex: -1,
    editedItem: {
      id: 0,
      document_id: 0,
      category_id: 0,
      document_line_options: [],
      created_at: "",
      updated_at: "",
    },
    defaultItem: {
      id: 0,
      document_id: 0,
      category_id: 0,
      document_line_options: [],
      created_at: "",
      updated_at: "",
    },

    // categoryName
    categoryNameRules: [
      (v) => !!v || "Required",
      (v) =>
        /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
        "Only english letters, apostrophes and hyphens are allowed",
      (v) => v.length >= 3 || "Can not be less than 3 characters",
      (v) => v.length <= 100 || "Can not be more than 100 characters",
    ],

    // document id
    documentIdRules: [(v) => !!v || "Required"],

    items: [{ title: "View" }, { title: "Archive" }, { title: "Delete" }],

    images: [],

    zoomerOptions: {
      zoomFactor: 3,
      pane: "pane",
      hoverDelay: 300,
      namespace: "zoomer-left",
      move_by_click: false,
      scroll_items: 4,
      choosed_thumb_border_color: "#dd2c00",
      scroller_position: "left",
      zoomer_pane_position: "right",
    },

    model: 0,
    colors: ["primary", "secondary", "yellow darken-2", "red", "orange"],

    fab: false,

    ocr_data_object: {},

    // files
    files: null,
    filesRules: [(v) => !!v || "Required"],

    imageIndex: -1,

    multiple: true,

    // categoryColumns
    categoryColumns: [],

    // forCurrentImageOnly
    forCurrentImageOnly: true,

    aiModels: [],
    aiModel: {},
    aiModelName: "",
    aiModelId: 0,
    aiModelNamesList: [],
    // aiModelName
    aiModelNameRules: [(v) => !!v || "Required"],

    pdfCurrentPage: 1,
    pdfPageCount: 1,

    decrementPdfCurrentPageDisabled: false,
    incrementPdfCurrentPageDisabled: false,

    customClass: "padding-bottom",
  }),

  mounted() {
    this.key++;
  },

  computed: {
    ...mapState({ auth: "auth", roi: "roi" }),

    formTitle() {
      return this.editedIndex === -1
        ? "New Document Line"
        : "Edit Document Line";
    },

    // formImageTitle
    formImageTitle() {
      return this.imageIndex === -1 ? "New Image" : "Edit Image";
    },

    // rawData
    rawData(){
      if("data" in this.ocr_data_object){
        return this.ocr_data_object.data;
      }

      return [];
    },

    // tableHeaders
    tableHeaders() {
      if (this.rawData.length === 0) {
        return [];
      }
      
      // Include 'index' field in headers
      return Object.entries(this.rawData[0]).map(([key, value]) => {
        return { text: value, value: key };
      });
    },

    // tableItems
    tableItems() {
      if (this.rawData.length === 0) {
        return [];
      }

      // Include 'index' field in each item
      return this.rawData.slice(1);
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },

    // image
    image(val) {
      if ("ocr_data_object" in val) {
        this.ocr_data_object = val.ocr_data_object;
      }

      if ("data" in val && val.data.document_lines.length > 0) {
        this.documentLines.push(...val.data.document_lines);
        this.documentLines = this.documentLines.concat(val.data.document_lines);

        this.resizeDocumentLines(val.id);
      }

      this.setImageDocumentLines();
    },

    // categoryName
    categoryName() {
      // setDocumentLineOptionsTextValue
      this.setDocumentLineOptionsTextValue();
    },

    // roi.image
    "roi.image"(val) {
      if ("id" in val) {
        var image = this.images.find((image) => image.id == val.id);

        var index = this.images.indexOf(image);

        Object.assign(this.images[index], val);

        this.image = val;
      }
    },

    // aiModelName
    aiModelName() {
      if (this.aiModelName != "") {
        var id = this.$helpers.getIdByNameFromList(
          this.aiModels,
          this.aiModelName
        );

        if (id > 0) {
          this.aiModelId = id;

          this.aiModel = this.aiModels.find((aiModel) => aiModel.id == id);

          if (this.aiModel.status != "Ready To Use") {
            this.$swal({
              toast: true,
              position: "top-end",
              icon: "warning",
              title: "Ai Model " + this.aiModel.name + " Is Not Ready To Use",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        }
      }

      // console.log("this.aiModelId", this.aiModelId);
    },

    // documentLines
    documentLines() {
      this.setImageDocumentLines();
    },
  },

  created() {
    if (!this.$can("document_line_access")) {
      this.$router.push("/dashboard/inbox");
    }

    this.getApiDocumentById();
    // this.getApiDocumentLines();
    this.getApiCategories();
    // this.getApiDocuments();

    // getApiAiModels
    this.getApiAiModels();

    this.setROIImgSrc("");
    this.setROIImgId(0);
    this.setROIDialogRoi(false);
  },

  methods: {
    ...mapMutations({
      resetAuthState: "auth/resetState",
      setUser: "auth/setUser",

      setROIImgSrc: "roi/setImgSrc",
      setROIImgId: "roi/setImgId",
      setROIDialogRoi: "roi/setDialogRoi",
    }),

    // decrementPdfCurrentPage
    decrementPdfCurrentPage() {
      this.incrementPdfCurrentPageDisabled = false;

      if (this.pdfCurrentPage > 1) {
        this.pdfCurrentPage--;
        this.decrementPdfCurrentPageDisabled = false;
      } else {
        this.decrementPdfCurrentPageDisabled = true;
      }
    },

    // incrementPdfCurrentPage
    incrementPdfCurrentPage() {
      this.decrementPdfCurrentPageDisabled = false;

      if (this.pdfCurrentPage < this.pdfPageCount) {
        this.pdfCurrentPage++;
        this.incrementPdfCurrentPageDisabled = false;
      } else {
        this.incrementPdfCurrentPageDisabled = true;
      }
    },

    // setImageDocumentLines
    setImageDocumentLines() {
      var imageDocumentLines = [];

      this.documentLines.forEach((imageDocumentLine) => {
        if (
          imageDocumentLine.image_id == this.image.id ||
          imageDocumentLine.image_id == null
        ) {
          imageDocumentLines.push(imageDocumentLine);
        }
      });

      this.imageDocumentLines = imageDocumentLines;
    },

    // resizeDocumentLines
    resizeDocumentLines(imageId) {
      this.documentLines.forEach((imageDocumentLine) => {
        if (
          imageDocumentLine.image_id == imageId &&
          imageDocumentLine.changed_by_system
        ) {
          var index = this.documentLines.indexOf(imageDocumentLine);

          this.documentLines.splice(index, 1);

          /* index = this.imageDocumentLines.indexOf(imageDocumentLine);

          if (index > 0) {
            this.imageDocumentLines.splice(index, 1);
          } */
        }
      });
    },

    // setROI
    async setROI(image) {
      this.imgSrc = image.url;

      var data = await this.getImageFileById(this.image.id);

      if (data != "" && "base64" in data) {
        var base64 = data.base64;
        var fileType = this.image.name.split(".").pop();

        // addDataUrl
        this.imgSrc = this.addDataUrl(base64, fileType);

        // this.setROIImgSrc(this.imgSrc);
        this.setROIImgId(this.image.id);

        this.setROIDialogRoi(true);
        // this.dialogRoi = true;
      }
    },

    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },

    toTop() {
      this.$vuetify.goTo(0);
    },

    // setDocumentLineOptionsTextValue
    setDocumentLineOptionsTextValue() {
      var id = this.$helpers.getIdByNameFromList(
        this.categories,
        this.categoryName
      );

      if (id > 0) {
        this.categoryId = id;
        this.categoryNameDisplay = this.categoryName;
        // getHeadersAndCategoryColumns
        this.getHeadersAndCategoryColumns(this.categoryId);

        this.forCurrentImageOnly = false;
      }

      // categoryId
      // console.log("categoryId", this.categoryId);
    },

    // validateForm
    validateForm() {
      this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          this.updateApiDocumentLineWithCategoryAndCategoryColumns();
        } else {
          this.createApiDocumentLineWithCategoryAndCategoryColumns();
        }
      }
    },

    editItem(item) {
      this.editedIndex = this.documentLines.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;

      var document_line_options = item.document_line_options;

      // documentLineOptionsTextValue
      for (var i = 0; i < this.documentLineOptionsTextValue.length; i++) {
        var documentLineOption = document_line_options.find(
          (documentLineOption) =>
            documentLineOption.category_column_id ==
            this.documentLineOptionsTextValue[i].category_column_id
        );

        this.documentLineOptionsTextValue[i].model = documentLineOption.value;
      }

      // forCurrentImageOnly
      if (item.image_id != null) {
        this.forCurrentImageOnly = true;
      } else {
        this.forCurrentImageOnly = false;
      }
    },

    deleteItem(item) {
      this.editedIndex = this.documentLines.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.deleteApiDocumentLine();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.categoryName = "";
      });

      this.$refs.form.resetValidation();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.validateForm();
    },

    // validateFormImage
    validateFormImage() {
      this.$refs.formImage.validate();

      if (this.$refs.formImage.validate()) {
        if (this.imageIndex > -1) {
          this.updateApiImage();
        } else {
          this.createApiImage();
        }
      }
    },

    // validateFormAiModel
    validateFormAiModel() {
      this.$refs.formAiModel.validate();

      if (this.$refs.formAiModel.validate()) {
        if (this.aiModel.status != "Ready To Use") {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Ai Model " + this.aiModel.name + " Is Not Ready To Use",
          });
        } else {
          this.doOCRWithCustomAiModel();
        }
      }
    },

    // addImage
    addImage() {
      this.imageIndex = -1;

      this.dialogImage = true;
    },

    // editImage
    editImage(image) {
      this.imageIndex = this.images.indexOf(image);
      // this.editedImage = Object.assign({}, image);
      this.dialogImage = true;

      /* if (this.editedIndex == -1) {
        this.multiple = true;
      } else {
        this.multiple = false;
      } */
    },

    // deleteImage
    deleteImage(image) {
      this.imageIndex = this.images.indexOf(image);
      // this.editedImage = Object.assign({}, image);
      this.dialogDeleteImage = true;
    },

    // deleteImageConfirm
    deleteImageConfirm() {
      this.deleteApiImage();
    },

    // closeImage
    closeImage() {
      this.dialogImage = false;
      /* this.$nextTick(() => {
        this.editedImage = Object.assign({}, this.defaultImage);
        this.editedIndex = -1;
      });
 */
      this.files = null;
      this.$refs.formImage.reset();
    },

    // closeDeleteImage
    closeDeleteImage() {
      this.dialogDeleteImage = false;
      // this.$nextTick(() => {
      //   this.editedImage = Object.assign({}, this.defaultImage);
      //   this.editedIndex = -1;
      // });
    },

    // saveImage
    saveImage() {
      this.validateFormImage();
    },
    //

    // deleteDocumentLines
    deleteDocumentLines(image) {
      this.imageIndex = this.images.indexOf(image);

      this.dialogDeleteDocumentLines = true;
    },

    // closeDeleteDocumentLines
    closeDeleteDocumentLines() {
      this.dialogDeleteDocumentLines = false;
    },

    // deleteDocumentLinesConfirm
    deleteDocumentLinesConfirm() {
      this.deleteApiDocumentLines();
    },

    generateCategoryId() {
      let categoryId = 0;

      var result = this.categories.filter((obj) => {
        return obj.name === this.categoryName;
      });

      categoryId = result[0].id;

      return categoryId;
    },

    // changeImage
    changeImage(image) {
      this.image = image;

      // console.log("image", image);
    },

    // Get Headers And Category Columns
    async getHeadersAndCategoryColumns(id) {
      this.overlay = true;

      const res = await this.$helpers.getApiData(
        `categories/get-headers-and-category-columns/${id}`
      );

      if (res.status == 200) {
        // headers
        if ("headers" in res.data) {
          this.headers = res.data.headers;
        }

        // categoryColumns
        if ("category_columns" in res.data) {
          this.categoryColumns = res.data.category_columns;
        }

        this.documentLineOptionsTextValue = [];

        // documentLineOptionsTextValue
        for (const element of this.headers) {
          // element.value
          if (
            element.value != "id" &&
            element.value != "created_at" &&
            element.value != "updated_at" &&
            element.value != "actions"
          ) {
            this.documentLineOptionsTextValue.push({
              text: element.text,
              category_column_id: element.category_column_id,
              value: element.value,
              model: "",
            });
          }
        }
      }

      this.overlay = false;
    },

    // Get Api Document Lines
    async getApiDocumentLines() {
      try {
        this.overlay = true;

        const res = await this.axios.get("documentlines");

        // console.log(res);

        if (res.status == 200) {
          this.documentLines = res.data;
        }
      } catch (error) {
        let res = error.response;
        let data = res.data;

        if (error.response.status == 401) {
          this.resetAuthState();
          // console.log(res);

          if (this.redirect !== "") {
            this.$router.push("/login?redirect=" + this.redirect);
          } else {
            this.$router.push("/login");
          }
        } else if (error.response.status == 400) {
          // console.log(res);

          // const errors = data.errors;

          if ("message" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.message,
            });
          }

          // console.log(errors);
        } else if (error.response.status == 500) {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      } finally {
        this.overlay = false;
      }
    },

    // Create Api Document Line
    async createApiDocumentLine() {
      try {
        this.overlay = true;

        let reqData = {
          category_id: this.generateCategoryId(),
          document_id: parseInt(this.document.id),
        };

        // console.log(reqData);

        const res = await this.axios.post("documentlines", reqData);

        // console.log(res);

        if (res.status == 200) {
          this.$swal({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "Document Line Created Successfully!",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });

          let data = res.data;

          this.documentLines.push(data);

          this.close();
        }
      } catch (error) {
        let res = error.response;
        let data = res.data;

        if (error.response.status == 401) {
          this.resetAuthState();

          if (this.redirect !== "") {
            this.$router.push("/login?redirect=" + this.redirect);
          } else {
            this.$router.push("/login");
          }
        } else if (error.response.status == 400) {
          // console.log(res);

          // const errors = data.errors;

          if ("message" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.message,
            });
          }

          // console.log(errors);
        } else if (error.response.status == 500) {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      } finally {
        this.overlay = false;
      }
    },

    // Create Api Document Line With Category and Category Columns
    async createApiDocumentLineWithCategoryAndCategoryColumns() {
      try {
        this.overlay = true;

        var document_line_options = [];

        // console.log(this.documentLineOptionsTextValue);

        // documentLineOptions
        for (const element of this.documentLineOptionsTextValue) {
          // console.log("element", element);

          document_line_options.push({
            category_column_id: element.category_column_id,
            value: element.model,
          });
        }

        let reqData = {
          category_id: this.categoryId,
          document_id: parseInt(this.document.id),
          document_line_options: document_line_options,
        };

        // forCurrentImageOnly
        if (this.forCurrentImageOnly && "id" in this.image) {
          reqData.image_id = this.image.id;
        } else {
          reqData.image_id = null;
        }

        // console.log(reqData);

        const res = await this.axios.post(
          "documentlines/with-category-columns",
          reqData
        );

        // console.log(res);

        if (res.status == 200) {
          this.$swal({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "Document Line Created Successfully!",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });

          let data = res.data;

          this.documentLines.push(data.data);

          // documentLineOptionsTextValue
          for (var i = 0; i < this.documentLineOptionsTextValue.length; i++) {
            this.documentLineOptionsTextValue[i].model = "";
          }

          this.close();
        }
      } catch (error) {
        let res = error.response;
        let data = res.data;

        if (error.response.status == 401) {
          this.resetAuthState();

          if (this.redirect !== "") {
            this.$router.push("/login?redirect=" + this.redirect);
          } else {
            this.$router.push("/login");
          }
        } else if (error.response.status == 400) {
          // console.log(res);

          // const errors = data.errors;

          if ("message" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.message,
            });
          }

          // console.log(errors);
        } else if (error.response.status == 500) {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      } finally {
        this.overlay = false;
      }
    },

    // Update Api Document Line
    async updateApiDocumentLine() {
      try {
        this.overlay = true;

        let reqData = {
          id: this.editedItem.id,
          category_id: this.generateCategoryId(),
          document_id: parseInt(this.document.id),
        };

        const res = await this.axios.put(
          `documentlines/${this.editedItem.id}`,
          reqData
        );

        // console.log(res);

        if (res.status == 200) {
          this.$swal({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "Document Line Updated Successfully!",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });

          Object.assign(this.documentLines[this.editedIndex], res.data);

          this.close();
        }
      } catch (error) {
        let res = error.response;
        let data = res.data;

        if (error.response.status == 401) {
          this.resetAuthState();

          if (this.redirect !== "") {
            this.$router.push("/login?redirect=" + this.redirect);
          } else {
            this.$router.push("/login");
          }
        } else if (error.response.status == 400) {
          // console.log(res);

          // const errors = data.errors;

          if ("message" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.message,
            });
          }

          // console.log(errors);
        } else if (error.response.status == 500) {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      } finally {
        this.overlay = false;
      }
    },

    // Update Api Document Line With Category and Category Columns
    async updateApiDocumentLineWithCategoryAndCategoryColumns() {
      try {
        this.overlay = true;

        var document_line_options = [];

        // console.log(this.documentLineOptionsTextValue);

        // documentLineOptions
        for (const element of this.documentLineOptionsTextValue) {
          // console.log("element", element);

          var documentLineOption = this.editedItem.document_line_options.find(
            (documentLineOption) =>
              documentLineOption.category_column_id ==
              element.category_column_id
          );

          document_line_options.push({
            id: documentLineOption.id,
            category_column_id: element.category_column_id,
            document_line_id: this.editedItem.id,
            value: element.model,
          });
        }

        let reqData = {
          id: this.editedItem.id,
          category_id: this.categoryId,
          document_id: parseInt(this.document.id),
          document_line_options: document_line_options,
        };

        // forCurrentImageOnly
        if (this.forCurrentImageOnly && "id" in this.image) {
          reqData.image_id = this.image.id;
        } else {
          reqData.image_id = null;
        }

        // console.log(reqData);

        const res = await this.axios.put(
          `documentlines/with-category-columns/${this.editedItem.id}`,
          reqData
        );

        // console.log(res);

        if (res.status == 200) {
          this.$swal({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "Document Line Updated Successfully!",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });

          let data = res.data;

          // console.log("data", data);

          Object.assign(this.documentLines[this.editedIndex], data.data);

          // documentLineOptionsTextValue
          for (var i = 0; i < this.documentLineOptionsTextValue.length; i++) {
            this.documentLineOptionsTextValue[i].model = "";
          }

          this.close();
        }
      } catch (error) {
        let res = error.response;
        let data = res.data;

        if (error.response.status == 401) {
          this.resetAuthState();

          if (this.redirect !== "") {
            this.$router.push("/login?redirect=" + this.redirect);
          } else {
            this.$router.push("/login");
          }
        } else if (error.response.status == 400) {
          // console.log(res);

          // const errors = data.errors;

          if ("message" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.message,
            });
          }

          // console.log(errors);
        } else if (error.response.status == 500) {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      } finally {
        this.overlay = false;
      }
    },

    // Delete Api Document Line
    async deleteApiDocumentLine() {
      try {
        this.overlay = true;

        const res = await this.axios.delete(
          `documentlines/${this.editedItem.id}`
        );

        // console.log(res);

        if (res.status == 200) {
          this.$swal({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "Document Line Deleted Successfully!",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });

          this.documentLines.splice(this.editedIndex, 1);
          this.closeDelete();
        }
      } catch (error) {
        let res = error.response;
        let data = res.data;

        if (error.response.status == 401) {
          this.resetAuthState();

          if (this.redirect !== "") {
            this.$router.push("/login?redirect=" + this.redirect);
          } else {
            this.$router.push("/login");
          }
        } else if (error.response.status == 400) {
          // console.log(res);

          // const errors = data.errors;

          if ("message" in data) {
            this.$swal({
              icon: "error",
              title: "Oops..." + this.editedItem.id,
              text: data.message,
            });
          }

          // console.log(errors);
        } else if (error.response.status == 500) {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      } finally {
        this.overlay = false;
      }
    },

    // doOCR
    async doOCR() {
      this.overlay = true;

      const res = await this.$helpers.createApiData(
        `images/do-ocr-to-image-dynamic/${this.image.id}`,
        {},
        "OCR Done Successfully!"
      );

      if (res.status == 200) {
        var data = res.data;

        var image = this.images.find((image) => image.id == this.image.id);

        var index = this.images.indexOf(image);

        Object.assign(this.images[index], data);

        this.image = data;

        // getUserDetails
        this.getUserDetails();
      }

      this.overlay = false;
    },

    // doOCRWithCustomAiModel
    async doOCRWithCustomAiModel() {
      this.overlay = true;

      const res = await this.$helpers.createApiData(
        `images/do-ocr-to-image-dynamic-and-use-custom-ai-model/${this.image.id}/${this.aiModelId}`,
        {},
        "OCR Done Successfully!"
      );

      if (res.status == 200) {
        var data = res.data;

        var image = this.images.find((image) => image.id == this.image.id);

        var index = this.images.indexOf(image);

        Object.assign(this.images[index], data);

        this.image = data;

        this.dialogUseCustomAiModel = false;

        // getUserDetails
        this.getUserDetails();
      }

      this.overlay = false;
    },

    // Get Api Categories
    async getApiCategories() {
      try {
        this.overlay = true;

        const res = await this.axios.get("categories");

        // console.log(res);

        if (res.status == 200) {
          let data = res.data;
          this.categories = data;

          for (var category of data) {
            this.categoryNamesList.push(category.name);
          }

          this.categoryNamesList = this.categoryNamesList.sort();
        }
      } catch (error) {
        let res = error.response;
        let data = res.data;

        if (error.response.status == 401) {
          this.resetAuthState();
          // console.log(res);

          if (this.redirect !== "") {
            this.$router.push("/login?redirect=" + this.redirect);
          } else {
            this.$router.push("/login");
          }
        } else if (error.response.status == 400) {
          // console.log(res);

          // const errors = data.errors;

          if ("message" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.message,
            });
          }

          // console.log(errors);
        } else if (error.response.status == 500) {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      } finally {
        this.overlay = false;
      }
    },

    // Get Api Documents
    async getApiDocuments() {
      try {
        this.overlay = true;

        const res = await this.axios.get("documents");

        // console.log(res);

        if (res.status == 200) {
          let data = res.data;
          this.documents = data;

          for (var document of data) {
            this.documentIdsList.push(document.id);
          }

          //   this.documentIdsList = this.documentIdsList.sort();
        }
      } catch (error) {
        let res = error.response;
        let data = res.data;

        if (error.response.status == 401) {
          this.resetAuthState();
          // console.log(res);

          if (this.redirect !== "") {
            this.$router.push("/login?redirect=" + this.redirect);
          } else {
            this.$router.push("/login");
          }
        } else if (error.response.status == 400) {
          // console.log(res);

          // const errors = data.errors;

          if ("message" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.message,
            });
          }

          // console.log(errors);
        } else if (error.response.status == 500) {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      } finally {
        this.overlay = false;
      }
    },

    // Get Api Document By Id
    async getApiDocumentById() {
      try {
        this.overlay = true;

        let id = this.$route.params.id;

        const res = await this.axios.get(`documents/${id}`);

        // console.log(res);

        if (res.status == 200) {
          let data = res.data;
          this.document = data;
          this.documentLines = data.documentlines;

          this.images = data.images;

          if (this.images.length > 0) {
            this.image = this.images[0];
          }

          this.documentCreatedAt = this.moment(this.document.created_at).format(
            "YYYY-MM-DD"
          );

          this.documentUpdatedAt = this.moment(this.document.updated_at).format(
            "YYYY-MM-DD"
          );

          // data
          if ("data" in data) {
            // headers
            if ("headers" in data.data) {
              this.headers = data.data.headers;
            }

            // categoryNameDisplay
            if ("category_name" in data.data) {
              this.categoryNameDisplay = data.data.category_name;
            }

            // documentLineOptionsTextValue
            for (const element of this.headers) {
              // element.value
              if (
                element.value != "id" &&
                element.value != "created_at" &&
                element.value != "updated_at" &&
                element.value != "actions"
              ) {
                this.documentLineOptionsTextValue.push({
                  text: element.text,
                  category_column_id: element.category_column_id,
                  value: element.value,
                  model: "",
                });
              }
            }

            // data
            if ("data" in data.data) {
              this.documentLines = data.data.data;
            }

            // documentLineOptions
            if (this.documentLines.length > 0) {
              if ("document_line_options" in this.documentLines[0]) {
                this.documentLineOptions =
                  this.documentLines[0].document_line_options;
              }
            }
          }

          if ("category_id" in data.data) {
            this.categoryId = data.data.category_id;
          }

          // console.log(this.images);
        }
      } catch (error) {
        let res = error.response;
        let data = res.data;

        this.$router.push({ name: "DashboardInbox" });

        if (error.response.status == 401) {
          this.resetAuthState();
          // console.log(res);

          if (this.redirect !== "") {
            this.$router.push("/login?redirect=" + this.redirect);
          } else {
            this.$router.push("/login");
          }
        } else if (error.response.status == 400) {
          // console.log(res);

          // const errors = data.errors;

          if ("message" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.message,
            });
          }

          // console.log(errors);
        } else if (error.response.status == 500) {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      } finally {
        this.overlay = false;
      }
    },

    // Create Api Image
    async createApiImage() {
      try {
        this.overlay = true;

        // let formData = new FormData();
        // formData.append("file", this.files);

        let formData = new FormData();

        for (var i = 0; i < this.files.length; i++) {
          let file = this.files[i];

          // console.log(i);

          formData.append("files[" + i + "]", file);
        }

        var id = this.$route.params.id;

        let res = await this.axios.post(
          `images/add-image-to-document/${id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // console.log(res);

        if (res.status == 200) {
          this.$swal({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "Image Created Successfully!",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });

          let data = res.data;

          let imagesApi = data.images;

          if (imagesApi.length > 0) {
            this.images.push(...imagesApi);
            this.image = imagesApi[0];
          }

          this.files = null;

          this.closeImage();
        }
      } catch (error) {
        let status = error.response.status;
        let data = error.response.data;

        if (status == 401) {
          this.resetAuthState();

          if (this.redirect !== "") {
            this.$router.push("/login?redirect=" + this.redirect);
          } else {
            this.$router.push("/login");
          }
        } else if (status == 400 || status == 422) {
          // const errors = data.errors;

          if ("message" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.message,
            });
          }

          // console.log(errors);
        } else if (error.response.status == 500) {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      } finally {
        this.overlay = false;
      }
    },

    // Update Api Image
    async updateApiImage() {
      try {
        this.overlay = true;

        var index = this.images.indexOf(this.image);

        var formData = new FormData();

        for (var i = 0; i < this.files.length; i++) {
          var file = this.files[i];

          // console.log(i);

          formData.append("files[" + i + "]", file);
        }

        const res = await this.axios.post(`images/${this.image.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        // console.log(res);

        if (res.status == 200) {
          this.$swal({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "Image Updated Successfully!",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });

          this.closeImage();

          Object.assign(this.images[index], res.data);

          this.files = null;
          this.$refs.formImage.reset();
        }
      } catch (error) {
        let status = error.response.status;
        let data = error.response.data;

        if (status == 401) {
          this.resetAuthState();

          if (this.redirect !== "") {
            this.$router.push("/login?redirect=" + this.redirect);
          } else {
            this.$router.push("/login");
          }
        } else if (status == 400 || status == 422) {
          // const errors = data.errors;

          if ("message" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.message,
            });
          }

          // console.log(errors);
        } else if (error.response.status == 500) {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      } finally {
        this.overlay = false;
      }
    },

    // Delete Api Image
    async deleteApiImage() {
      this.overlay = true;

      var index = this.images.indexOf(this.image);

      const res = await this.$helpers.deleteApiData(
        `images/${this.image.id}`,
        "Image Deleted Successfully!"
      );

      if (res.status == 200) {
        this.images.splice(index, 1);

        if (this.images.length > 0) {
          this.image = this.images[0];
        } else {
          this.image = {};
        }

        this.closeDeleteImage();
      }

      this.overlay = false;
    },

    // Delete Api DocumentLines
    async deleteApiDocumentLines() {
      this.overlay = true;

      const documentLineIds = this.documentLines.map(({ id }) => id);

      const res = await this.$helpers.createApiData(
        "documentlines/delete-multiple",
        {
          document_line_ids: documentLineIds,
        },
        "Document Line Changed Successfully!"
      );

      if (res.status == 200) {
        // documentLines
        this.documentLines = [];

        // documentLineOptionsTextValue
        this.documentLineOptionsTextValue = [];

        this.closeDeleteDocumentLines();
      }

      this.overlay = false;
    },

    // Get Image File By Id
    async getImageFileById(id) {
      this.overlay = true;

      var data = "";

      const res = await this.$helpers.getApiData(`images/file/${id}`);

      if (res.status == 200) {
        // console.log("data", res.data);

        data = res.data;
      }

      this.overlay = false;

      return data;
    },

    // Get Api Ai Models
    async getApiAiModels() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("ai-models");

      if (res.status == 200) {
        this.aiModels = res.data;

        this.aiModelNamesList = this.$helpers.getNamesFromList(this.aiModels);
      }

      this.overlay = false;
    },

    // getUserDetails
    async getUserDetails() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("user-details");

      if (res.status == 200) {
        const data = res.data;

        this.setUser(data);
      }

      this.overlay = false;
    },

    // addDataUrl
    addDataUrl(base64, fileType) {
      return "data:image/" + fileType + ";base64," + base64;
    },

    // getFileAndDownload
    async getFileAndDownload() {
      this.overlay = true;

      const res = await this.$helpers.getApiData(`images/file/${this.image.id}`);

      if (res.status == 200) {
        const data = res.data;

        const base64Data = data.base64;
        // fileName
        const fileName = this.image.name; 
        
        // Extract extension and determine MIME type
        const extension = fileName.split('.').pop().toLowerCase();
        const mimeType = this.getMimeType(extension);

        if (!mimeType) {
          // console.error('Unsupported file type');
          this.overlay = false;

          return;
        }

        this.downloadFile(base64Data, fileName, mimeType);
      }

      this.overlay = false;
    },
 
    // getMimeType
    getMimeType(extension) {
      switch(extension) {
        case 'jpg':
        case 'jpeg':
          return 'image/jpeg';
        case 'png':
          return 'image/png';
        case 'gif':
          return 'image/gif';
        case 'bmp':
          return 'image/bmp';
        case 'webp':
          return 'image/webp';
        case 'pdf':
          return 'application/pdf';
        case 'doc':
        case 'docx':
          return 'application/msword';
        case 'xls':
        case 'xlsx':
          return 'application/vnd.ms-excel';
        case 'ppt':
        case 'pptx':
          return 'application/vnd.ms-powerpoint';
        case 'txt':
          return 'text/plain';
        case 'csv':
          return 'text/csv';
        case 'zip':
          return 'application/zip';
        case 'rar':
          return 'application/x-rar-compressed';
        case 'mp3':
          return 'audio/mpeg';
        case 'wav':
          return 'audio/wav';
        case 'mp4':
          return 'video/mp4';
        case 'avi':
          return 'video/x-msvideo';
        // Add more cases if needed
        default:
          return null;
      }
    },

    // downloadFile
    downloadFile(base64Data, fileName, mimeType) {
      // Convert base64 to blob and initiate file download
      let realData = base64Data;

      // Convert to blob
      let blob = this.b64toBlob(realData, mimeType);

      // Use js-file-download to save the file
      fileDownload(blob, fileName);
    },

    // b64toBlob
    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;

      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, {type: contentType});
      return blob;
    }  
  },
};
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}

.padding-bottom {
  padding-bottom: 180px;
}
</style>
