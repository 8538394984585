<template>
  <div class="background">
    <InboxDetails />
  </div>
</template>

<script>
import InboxDetails from "@/components/elements/dashboard/inbox-details/InboxDetails";

export default {
  name: "DocumentManagementInboxDetails",

  components: {
    InboxDetails,
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
//
</style>
